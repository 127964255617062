<template>
  <div style="margin-bottom: 2rem">
    <div class="title1">
      <p class="title_top">CONNECT US</p>
      <p class="title_content blue">联系方式</p>
    </div>
    <div
      class="flex wrap"
      style="border-top: 1px solid #79c4ea; border-bottom: 1px solid #79c4ea"
    >
      <div
        class="col5 flex center start1"
        style="
          display: inline-block;
          margin: 2rem 0;
          padding-left: 1rem;
          box-sizing: border-box;
        "
      >
        <img v-lazy="require('../../assets/connect/way/3.png')" />
        <p style="margin-left: 1rem">
          0592-2283132&nbsp; &nbsp; &nbsp; &nbsp; 400-004-8820
        </p>
      </div>
      <div
        class="col5 flex center start1"
        style="
          display: inline-block;
          margin: 2rem 0;
          padding-left: 1rem;
          box-sizing: border-box;
        "
      >
        <img v-lazy="require('../../assets/connect/way/4.png')" />
        <p style="margin-left: 1rem">Lisijianding@163.com</p>
      </div>
      <div
        class="col5 flex center start1"
        style="
          display: inline-block;
          margin: 2rem 0;
          padding-left: 1rem;
          box-sizing: border-box;
        "
      >
        <img v-lazy="require('../../assets/connect/way/5.png')" />
        <p style="margin-left: 1rem">0592-2283932</p>
      </div>
      <div
        class="col5 flex center start1"
        style="
          display: inline-block;
          margin: 2rem 0;
          padding-left: 1rem;
          box-sizing: border-box;
        "
      >
        <img v-lazy="require('../../assets/connect/way/6.png')" />
        <p style="margin-left: 1rem">
          福建省厦门市湖里区湖里大道33号7楼（西侧）
        </p>
      </div>
    </div>
    <!-- <div class="flex wrap ">
            <div class="col5" >
                <div  style="text-align:left;padding: 2rem 0 0 1rem;">
                    法医临床：0592-2283132<br/><br/>法医毒物：0592-2283132<br/><br/>法医病理：0592-2283132      <br/><br/>QQ咨询号码1：2677884827<br/><br/>QQ咨询号码2：3441661325
                </div>
                
            </div>
            <div class="col5" >
                <div  style="text-align:left;padding: 2rem 0 0 1rem;">
                    法医物证：0592-2283132<br/><br/>痕迹鉴定：0592-2283132<br/><br/>文书鉴定：0592-2283132<br/><br/>声像资料鉴定：0592-2283132<br/><br/>微信公众号：厦门市历思司法鉴定所                </div>
                
            </div>
        </div> -->
    <div
      style="
        width: 100%;
        max-height: 600px;
        height: 60vw;
        min-height: 500px;
        margin: 2rem 0;
      "
    >
      <div class="map_top flex start1">
        <div class="flex center">
          <img
            style="margin-right: 10px; margin-left: 2rem"
            v-lazy="require('../../assets/connect/way/7.png')"
          />
          地址：福建省厦门市湖里区湖里大道 33号7层
        </div>
      </div>
      <div style="height: 85%; min-height: 400px">
        <div class="flex center" style="height: 100%; width: 100%">
          <Map :id="2"></Map>
        </div>
      </div>
    </div>
    <div class="box1 flex" style="margin: 4rem 0 2rem 0">
      <img
        style="width: 100px"
        v-lazy="require('../../assets/connect/way/1.png')"
      />
      <div
        class="flex center"
        style="margin-left: 2rem; text-align: left; letter-spacing: 2px"
      >
        1、乘坐地铁3号线到华荣路地铁站<br />2、乘坐地铁1号线到火炬园地铁站,转848、109路到华泰路<br />3、乘坐地铁2号线到体育中心地铁站，转16路到华泰路
      </div>
    </div>
    <div class="box1 flex" style="margin-bottom: 4rem">
      <img
        style="width: 100px"
        v-lazy="require('../../assets/connect/way/2.png')"
      />
      <div
        class="flex center"
        style="margin-left: 2rem; text-align: left; letter-spacing: 2px"
      >
        1、乘坐848、109、655路到华泰站。<br />2、乘坐94、16、14路到兴隆路华荣路口。<br />3、乘坐109、97、94、11、113、437、848、428、302、137、655路到石头皮山站。<br />4、乘坐26、59、109、843、848、655、956路到华昌站。
      </div>
    </div>
  </div>
</template>
<script>
import Map from "@/components/Map.vue";
export default {
  components: {
    Map,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.map_top {
  height: 12%;
  min-height: 70px;
  color: #fff;
  background: #008fd7;
}
</style>
